import React, { useRef, useEffect } from "react"
import styled, { useTheme } from "styled-components"
import * as d3 from "d3"
import useResizeObserver from "shared/hooks/useResizeObserver"
import {
  tooltipStyles,
  decimalToPercentage,
  toTextCase
} from "../../utils/chartHelpers"
import { md } from "mill/utils/breakpoints"

const Container = styled.div`
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  svg {
    position: relative;
    overflow: visible;
    height: 100%;
  }

  text {
    font-size: 1.2rem;
    fill: #909090;
  }

  .gridlines line {
    stroke: #f0f0f0;
    stroke-width: 2;
    stroke-dasharray: 6px;
  }

  .y-axis-label text {
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .tick:nth-child(even) {
    display: none;

    @media ${md} {
      display: block;
    }
  }

  ${tooltipStyles}
`

const HorizontalBarChart = ({ data, animate, dataKeys, colors }) => {
  const svgRef = useRef(null)
  const chartContainer = useRef(null)
  const dimensions = useResizeObserver(chartContainer)
  const theme = useTheme()
  const themeColors = colors.map(color => theme.colors[color])

  const barHeight = 10
  const barSeparator = 1
  const itemSeparator = 10
  const labelHeight = 15
  const offset = itemSeparator / 2
  const itemHeight =
    barHeight * dataKeys.length + barSeparator * (dataKeys.length - 1)
  const height = (itemHeight + itemSeparator) * (data.length || 1)

  useEffect(() => {
    if (data && chartContainer.current) {
      if (!dimensions) return
      const isMobile = dimensions.width < 400
      const translateXOffset = isMobile ? 100 : 200

      const getLabelText = label => {
        const maxLabelLength = isMobile ? 15 : 28
        return label.length > maxLabelLength
          ? `${label.slice(0, maxLabelLength)}...`
          : label
      }

      const svg = d3
        .select(svgRef.current)
        .attr("width", dimensions.width)
        .attr("height", height + labelHeight)
      const container = d3.select(chartContainer.current)

      // remove elements from previous renders
      svg.selectAll("*").remove()
      container.selectAll(".tooltip").remove()

      // Tooltip
      const tooltip = container.append("div").attr("class", "tooltip")

      // Setup scales
      const xScale = d3
        .scaleLinear()
        .range([0, dimensions.width - translateXOffset - 20])
        .domain([0, 1])

      const yScale = d3.scaleBand().range([height, 0]).domain([0, data.length])

      // Add in the grid
      const chart = svg
        .append("g")
        .attr("class", "chart")
        .attr("transform", `translate(${translateXOffset},0)`)

      // Add in the gridlines
      const xAxisGrid = d3
        .axisBottom(xScale)
        .tickSize(height)
        .tickFormat((d, i) => {
          return `${i * 10}%`
        })
        .ticks(10)
      chart.append("g").attr("class", "gridlines").call(xAxisGrid)
      d3.select(".gridlines .domain").remove()

      // Add in the bars
      const bars = chart
        .append("g")
        .attr("class", "bars")
        .selectAll("bars")
        .data(data)
        .enter()

      dataKeys.map((key, keyIndex) => {
        bars
          .append("rect")
          .attr("class", key)
          .attr("fill", themeColors[keyIndex])
          .attr("height", barHeight)
          .attr("width", d => {
            if (animate) {
              return xScale(0)
            } else {
              const value = xScale(d[key])
              return value < 0 ? 0 : value
            }
          })
          .attr("x", d => {
            return xScale(0)
          })
          .attr("y", (d, itemIndex) => {
            return (
              itemIndex * (itemHeight + itemSeparator) +
              keyIndex * (barHeight + barSeparator) +
              offset
            )
          })
          .on("mouseover", function (event, d) {
            tooltip
              .style("top", event.offsetY - 10 + "px")
              .style("left", event.offsetX + 10 + "px")
              .classed("active", true)

            tooltip.html(
              `
              ${toTextCase(key)}: ${decimalToPercentage(d[key])}%
              `
            )
          })
          .on("mousemove", function (event, d) {
            tooltip
              .style("top", event.offsetY - 10 + "px")
              .style("left", event.offsetX + 10 + "px")
          })
          .on("mouseout", function (d) {
            tooltip.classed("active", false)
          })
      })

      // Add in the yLabel
      const yAxisLabel = svg.append("g").attr("class", "y-axis-label")
      const yAxisLabelGroup = yAxisLabel
        .selectAll("y-axis-label")
        .data(data)
        .enter()
        .append("g")

      // This makes this chart a little bit campaign specific, but I think is
      // the right approach until we have another need.
      const campaignAnchor = yAxisLabelGroup
        .append("a")
        .attr("target", "_blank")
        .attr("href", (d, i) => {
          return `/admin/campaigns/${d.id}`
        })

      campaignAnchor
        .append("text")
        .text(d => {
          return getLabelText(d.name)
        })
        .attr("y", (d, i) => {
          const totalHeight = itemHeight + itemSeparator
          return i * totalHeight + itemHeight / 2 + 4 + offset
        })
        .attr("x", translateXOffset - 15)
        .attr("text-anchor", "end")
        .on("mouseover", function (event, d) {
          tooltip
            .style("top", event.offsetY - 10 + "px")
            .style("left", event.offsetX + 10 + "px")
            .classed("active", true)

          tooltip.html(`${d.name}`)
        })
        .on("mousemove", function (event, d) {
          tooltip
            .style("top", event.offsetY - 10 + "px")
            .style("left", event.offsetX + 10 + "px")
        })
        .on("mouseout", function (d) {
          tooltip.classed("active", false)
        })

      // Animation
      if (animate) {
        dataKeys.map(key => {
          svg
            .selectAll(`.bars .${key}`)
            .transition()
            .duration(800)
            .attr("width", d => {
              const value = xScale(d[key])
              return value < 0 ? 0 : value
            })
            .delay(function (d, i) {
              return i * 5
            })
        })
      }
    }
  }, [data, dimensions])

  return (
    <Container ref={chartContainer}>
      {data.length > 0 && <svg ref={svgRef}></svg>}
    </Container>
  )
}

export default HorizontalBarChart

HorizontalBarChart.defaultProps = {
  data: [],
  dataKeys: [],
  colors: ["primary", "tertiary", "quaternary"]
}
