import React, { Fragment } from "react"
import Box from "shared/components/Box"
import styled from "styled-components"

import DateRangeSelector from "mill/containers/DateRangeSelector"
import TeamSelector from "mill/containers/TeamSelector"
import TeamGroupSelector from "mill/containers/TeamGroupSelector"
import CampaignCollectionSelector from "mill/containers/CampaignCollectionSelector"

import FilterBar from "shared/components/FilterBar"
import { sm, lg } from "mill/utils/breakpoints"

const InsightsFilterContainer = styled.div`
  @media ${lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`

const MobileGrid = styled.div`
  @media ${sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`

const InsightsHeader = ({ startDate, endDate }) => {
  return (
    <Box paddingTop="medium">
      <FilterBar initiallyOpen toggleable={false}>
        <InsightsFilterContainer>
          <DateRangeSelector fallbackStart={startDate} fallbackEnd={endDate} />
          <CampaignCollectionSelector
            placeholder="All collections"
            label="Campaign collections"
          />
        </InsightsFilterContainer>
        <MobileGrid>
          <TeamSelector placeholder="All teams" />
          <TeamGroupSelector placeholder="All groups" />
        </MobileGrid>
      </FilterBar>
    </Box>
  )
}

export default InsightsHeader
